import React, { useState } from 'react';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import AddBroker from '../../../AddBroker';

const BrokerCard = ({ brokerData, refetch }) => {
  const {
    firstName,
    lastName,
    photoUrl,
    brokerages,
    specialityAssetType,
    phone,
    email,
    city,
  } = brokerData;

  const [modal, setModal] = useState(false);

  const toggleDialog = () => setModal(!modal);

  return (
    <Card className="broker-card border-0 mb-2">
      <CardBody>
        <div className="d-flex w-100">
          <div>
            <img
              src={photoUrl}
              alt={firstName}
              className="rounded-circle object-fit-cover"
              height={73}
              width={73}
            />
          </div>

          <div className="ml-3 w-100">
            <div className="d-flex justify-content-between">
              <div>
                <h6 className="mb-0 font-size-16 mb-1">
                  {firstName} {lastName}
                </h6>
                <p className="mb-3 font-size-14">
                  {specialityAssetType ? specialityAssetType : 'N/A'}
                  <span className="ml-4">
                    <FontAwesomeIcon
                      icon={faMapMarker}
                      className="fa-1x mr-1"
                    />

                    {city ? city : 'N/A'}
                  </span>
                </p>
              </div>
              <div className="d-flex">
                <div className="mr-4">
                  <div className="card-checkbox">
                    <FormGroup check style={{ top: '5px', left: '10px' }}>
                      <Input type="checkbox" />
                      <Label
                        check
                        className="position-relative font-size-14"
                        style={{ top: '2px' }}
                      >
                        Lead Broker
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <div>
                  <Button
                    color="link"
                    size="sm"
                    className="px-0"
                    onClick={toggleDialog}
                  >
                    View Profile
                  </Button>
                </div>
              </div>
            </div>
            <Row>
              <Col md={4}>
                <p className="mr-4 font-size-14">
                  Phone No:
                  <span className="ml-1">{phone ? phone : 'N/A'}</span>
                </p>
              </Col>

              <Col md={8}>
                <p className="mb-0 font-size-14 d-flex">
                  Email:
                  <span
                    className="ml-1 d-block ellipsis"
                    title={email ? email : ''}
                  >
                    {email ? email : 'N/A'}
                  </span>
                </p>
              </Col>
            </Row>
            <div
              style={{ background: '#F8F8F8' }}
              className="p-2 d-flex align-items-center rounded"
            >
              <img
                src={brokerages[0]?.logoUrl}
                alt="logo"
                className="object-fit-fill"
                height={29}
              />
              <p className="ml-3 mb-0 font-size-14">{brokerages[0]?.name}</p>
            </div>
          </div>
        </div>
        <AddBroker
          toggleDialog={toggleDialog}
          modal={modal}
          brokerData={brokerData}
          refetch={refetch}
        />
      </CardBody>
    </Card>
  );
};

export default BrokerCard;
