import React, { useContext, useState } from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import BrokerCard from './components/Brokercard';
import AddBroker from '../AddBroker';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import PageLoader from 'pages/Search/common/PageLoader';

const Brokers = () => {
  const {
    loading,
    error,
    refetchAssetDetail,
    assetDetail: getAssetDetails,
  } = useContext(assetDetailContext);

  if (loading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;

  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [modal, setModal] = useState(false);

  const toggleDialog = () => setModal(!modal);

  const { asset } = getAssetDetails || [];

  return (
    <div>
      <div className="d-flex justify-content-between mt-3 mb-2">
        <h6 className="mb-0 font-size-18">Brokers</h6>
        {/* <Button color="primary" size="sm" onClick={toggleDialog}>
          Add Brokers
        </Button> */}
      </div>
      <div className="brokers-content">
        <Nav tabs className="mb-3">
          <NavItem>
            <NavLink
              className={
                activeTab === '1' ? 'active font-size-14' : 'font-size-14'
              }
              onClick={() => {
                toggle('1');
              }}
            >
              Crexi default broker
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          activeTab={activeTab}
          className="mb-2 overflow-auto"
          style={{ height: 'calc(100vh - 160px)' }}
        >
          <TabPane tabId="1">
            {asset?.brokers.map((brokerData, index) => {
              return (
                <BrokerCard
                  brokerData={brokerData}
                  key={brokerData.id}
                  refetch={refetchAssetDetail}
                />
              );
            })}
          </TabPane>
        </TabContent>
        <AddBroker toggleDialog={toggleDialog} modal={modal} />
      </div>
    </div>
  );
};
export default Brokers;
