import { gql } from '@apollo/client';

export const SEARCH_BROKER_ASSETS = gql`
  query searchAssets(
    $firstName: String
    $lastName: String
    $companyName: String
    $limit: Int
    $offset: Int
    $auctionData: Boolean
  ) {
    searchAssets(
      firstName: $firstName
      lastName: $lastName
      companyName: $companyName
      limit: $limit
      offset: $offset
      auctionData: $auctionData
    )
  }
`;

export const GET_ASSESTS_DETAILS = gql`
  query getAssetDetails($assetId: Int!) {
    getAssetDetails(assetId: $assetId)
  }
`;

export const SEND_MAP_DATA = gql`
  mutation addQuotePropertiesToHubspot(
    $data: JSON!
    $userId: Int
    $assetId: Int
  ) {
    addQuotePropertiesToHubspot(data: $data, userId: $userId, assetId: $assetId)
  }
`;

export const GET_ASSEST_PLAN = gql`
  query getAssetPlan($assetId: Int!) {
    getAssetPlan(assetId: $assetId)
  }
`;

export const ADD_ASSEST_PLAN = gql`
  mutation addAssetPlan(
    $quantity: Int
    $validFrom: Time
    $validTill: Time
    $assetId: Int!
    $planId: Int!
  ) {
    addAssetPlan(
      quantity: $quantity
      validFrom: $validFrom
      validTill: $validTill
      assetId: $assetId
      planId: $planId
    )
  }
`;

export const ADD_ASSET_DETAILS = gql`
  mutation addAssetDetails(
    $nearbyCities: [CitiesInput]
    $nearbyCallouts: [CalloutsInput]
    $callToAction: CallToActionInput
    $interiorVideoContact: InteriorVideoContactInput
    $eliteAuction: String
    $assetId: Int!
    $status: String!
    $projectPlan: String!
    $mapScreenshot: String
    $mapGeopoints: String
    $auction: AuctionInput
  ) {
    addAssetDetails(
      nearbyCities: $nearbyCities
      nearbyCallouts: $nearbyCallouts
      callToAction: $callToAction
      interiorVideoContact: $interiorVideoContact
      assetId: $assetId
      eliteAuction: $eliteAuction
      status: $status
      projectPlan: $projectPlan
      mapScreenshot: $mapScreenshot
      mapGeopoints: $mapGeopoints
      auction: $auction
    )
  }
`;

export const SYNC_ASSET_DETAILS = gql`
  mutation syncAssetDetails($assetId: Int!) {
    syncAssetDetails(assetId: $assetId)
  }
`;

export const GET_PENDING_ASSETS = gql`
  query getPendingAssets($limit: Int, $offset: Int) {
    getPendingAssets(limit: $limit, offset: $offset)
  }
`;

export const DELETE_PENDING_ASSET = gql`
  mutation deletePendingAssets($assetId: Int!, $status: String!) {
    deletePendingAssets(assetId: $assetId, status: $status)
  }
`;

export const CHECKOUT = gql`
  mutation checkout(
    $assetId: Int!
    $planId: Int
    $eliteAuction: String!
    $planName: String!
  ) {
    checkout(
      assetId: $assetId
      planId: $planId
      eliteAuction: $eliteAuction
      planName: $planName
    )
  }
`;

export const ASSET_GALLERIES_SYNCED = gql`
  subscription assetGalleriesSynced {
    assetGalleriesSynced
  }
`;

export const GET_ASSET_GALLERY = gql`
  query getAssetGalleryUrl($assetCrexiId: Int!) {
    getAssetGalleryUrl(assetCrexiId: $assetCrexiId)
  }
`;

export const PROJECT_CHECKOUT_STATUS = gql`
  query projectCheckoutStatus($assetId: Int!, $planName: String!) {
    projectCheckoutStatus(assetId: $assetId, planName: $planName)
  }
`;
