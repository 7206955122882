import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep, values as valuesOf, isEmpty, isObject } from 'lodash';
import { Row, Col, Button, Spinner } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import client from 'config/apollo';
import { TABS, getCurrentTab } from 'pages/Search/constant';
import {
  GET_ASSEST_PLAN,
  ADD_ASSET_DETAILS,
  SYNC_ASSET_DETAILS,
} from 'pages/Search/CrexiSearchGraphQL';
import DetailViewNextButton from 'pages/Search/common/DetailViewNextButton';
import { dateTimeFormatter, removeProperties } from 'utils/helper';
import { changeEliteAuction } from 'pages/Search/SalesActions';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import { SILVER } from 'constants/appConstants';
import SaveDetailWarningModal from '../SaveDetailWarningModal';

const PropertyDetailHeader = ({
  fieldKey,
  values,
  getAssetDetails,
  isValid,
  refetch,
  isDisabled,
  dirty,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    handleEditAsset,
    assetDetail,
    assetToEdit: { editStatus },
    isIVCDisabled,
    isGalleryLoading,
    setGalleryLoading,
    galleryImage,
  } = useContext(assetDetailContext);

  const [modalProp, setModalProp] = useState({
    isVisibleModal: false,
    path: null,
  });

  const { isVisibleModal } = modalProp;

  const { assetId } = useParams();

  const {
    location: { pathname },
  } = history;

  const { searchedBy, eliteAuction, plan } = useSelector(
    (state) => state.salesPortal
  );

  const propertyName = getAssetDetails ? getAssetDetails.asset.name : '';

  const toggleUnfinishedModal = () =>
    setModalProp({ isVisibleModal: !isVisibleModal });

  const currentTab = getCurrentTab(pathname);

  const { tabId, label } = currentTab || { tabId: 0, label: '' };

  let isCompleteIVC = true;
  if (values && values.interiorVideoContact && tabId === 5) {
    isCompleteIVC = Object.values(values.interiorVideoContact).every(
      (row) => row
    );
  }

  const [addAssetDetails] = useMutation(ADD_ASSET_DETAILS, {
    onCompleted: async (data) => {
      toast.success('Asset details added successfully');
      await refetch();

      dispatch(
        changeEliteAuction({
          eliteAuction: '',
        })
      );
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const [syncAssetDetails] = useMutation(SYNC_ASSET_DETAILS, {
    onCompleted: async ({ syncAssetDetails: { success, message } }) => {
      if (success) {
        await refetch();
        toast.success(
          'Asset data sync successfully! Gallery processing is in-progress'
        );
      } else {
        toast.error(message);
        history.push(searchedBy);
      }
    },
    onError: (e) => {
      setGalleryLoading(false);
      toast.error(e.message);
    },
  });

  const syncAsset = async () => {
    try {
      setGalleryLoading(true);
      await syncAssetDetails({
        variables: { assetId: parseInt(assetId, 10) },
      });
    } catch (e) {
      setGalleryLoading(false);
    }
    handleEditAsset(false, null);
  };

  const getPrevTab = async () => {
    try {
      const { getAssetPlan } = await client.readQuery({
        query: GET_ASSEST_PLAN,
        variables: {
          assetId: parseInt(assetId, 10),
        },
      });
      let prevTab = TABS[tabId - 1];
      if (
        (getAssetPlan && getAssetPlan.type === SILVER && tabId === 7) ||
        (plan.type === SILVER && tabId === 7)
      ) {
        prevTab = TABS[tabId - 2];
      }
      return prevTab;
    } catch (e) {
      toast.error(e.message);
    }
    return null;
  };

  const goToPreviousTab = async () => {
    if (editStatus && tabId === 5) {
      setModalProp({
        isVisibleModal: true,
      });
      return true;
    }
    const prevTab = await getPrevTab();

    if (prevTab) {
      history.push(`/crexi-search/property-detail/${assetId}${prevTab.link}`);
    }
    return true;
  };

  const save = async () => {
    let updatedAssetDetails = {};
    updatedAssetDetails = cloneDeep(getAssetDetails);
    updatedAssetDetails[fieldKey] = values[fieldKey];
    updatedAssetDetails.interiorVideoContact = {
      ...assetDetail.interiorVideoContact,
      ...updatedAssetDetails.interiorVideoContact,
    };
    const modifiedData = removeProperties(updatedAssetDetails);
    modifiedData.assetId = updatedAssetDetails.asset.id;
    modifiedData.status = 'Inprogress';
    if (eliteAuction) modifiedData.eliteAuction = eliteAuction;

    if (modifiedData.callToAction && !modifiedData.callToAction.text) {
      modifiedData.callToAction = null;
    }

    const hasAllEmptyField = valuesOf(modifiedData.interiorVideoContact).every(
      isEmpty
    );

    if (hasAllEmptyField) {
      modifiedData.interiorVideoContact = null;
    }

    if (modifiedData.interiorVideoContact) {
      const { shootTime } = modifiedData.interiorVideoContact;
      if (isObject(shootTime)) {
        modifiedData.interiorVideoContact.shootTime = dateTimeFormatter(
          shootTime,
          'hh:mm:ss'
        );
      }
    }

    delete modifiedData.asset;

    try {
      await addAssetDetails({
        variables: { projectPlan: plan.type ?? 'PLATINUM', ...modifiedData },
      });
    } catch (e) {
      toast.error(e.message);
    }
    if (tabId !== 7) history.push(searchedBy);
  };

  const handleDiscardChanges = async () => {
    handleEditAsset(false, null);
    const prevTab = await getPrevTab();
    setModalProp({
      isVisibleModal: false,
    });
    if (prevTab) {
      history.push(`/crexi-search/property-detail/${assetId}${prevTab.link}`);
    }
  };

  const handleSaveChanges = async () => {
    let updatedAssetDetails = {};
    updatedAssetDetails = cloneDeep(getAssetDetails);
    updatedAssetDetails.interiorVideoContact = {
      ...assetDetail.interiorVideoContact,
      ...values[fieldKey],
    };

    const modifiedData = removeProperties(updatedAssetDetails);
    modifiedData.assetId = updatedAssetDetails.asset.id;
    modifiedData.status = 'Inprogress';
    if (tabId === 7) modifiedData.status = 'Complete';
    if (modifiedData.interiorVideoContact) {
      const { shootTime } = modifiedData.interiorVideoContact;
      if (isObject(shootTime)) {
        modifiedData.interiorVideoContact.shootTime = dateTimeFormatter(
          shootTime,
          'hh:mm:ss'
        );
      }
    }
    if (isEmpty(modifiedData.interiorVideoContact)) {
      modifiedData.interiorVideoContact = null;
    }
    delete modifiedData.asset;
    try {
      await addAssetDetails({
        variables: modifiedData,
      });
    } catch (e) {
      toast.error(e.message);
    }
    setModalProp({
      isVisibleModal: false,
    });
    await handleEditAsset(false, null);
    const prevTab = await getPrevTab();
    if (prevTab) {
      history.push(`/crexi-search/property-detail/${assetId}${prevTab.link}`);
    }
  };

  return (
    <>
      <Row className="search-result-header py-2 align-items-center ">
        <Col sm="auto" className="m-auto m-lg-0">
          <h6 className="mb-lg-0">{label}</h6>
        </Col>
        <Col sm="auto" className="ml-auto mr-auto mr-lg-0">
          {tabId === 0 && (
            <>
              <Button
                className="ml-2 font-size-14 px-3"
                onClick={syncAsset}
                disabled={isGalleryLoading}
              >
                Sync
                {isGalleryLoading ? (
                  <Spinner size="sm" className="ml-2" />
                ) : null}
              </Button>
              <span className="side-border" />
            </>
          )}
          <Button
            className="save-btn font-size-14"
            disabled={dirty && isDisabled}
            onClick={save}
          >
            Save For Later
          </Button>
          <span className="side-border" />
          {tabId > 0 && (
            <Button
              color="primary"
              outline
              className="previous-btn font-size-14"
              onClick={goToPreviousTab}
            >
              Previous
            </Button>
          )}
          {tabId < 7 && (
            <DetailViewNextButton
              fieldKey={fieldKey}
              values={values}
              getAssetDetails={getAssetDetails}
              currentTab={currentTab}
              isValid={isValid}
              refetch={refetch}
              isDisabled={isDisabled}
            />
          )}
        </Col>
      </Row>
      {isVisibleModal && (
        <SaveDetailWarningModal
          isVisibleModal={isVisibleModal}
          propertyName={propertyName}
          toggleUnfinishedModal={toggleUnfinishedModal}
          handleDiscardChanges={handleDiscardChanges}
          handleSaveChanges={handleSaveChanges}
          isCompleteIVC={isCompleteIVC}
          isIVCDisabled={isIVCDisabled}
        />
      )}
    </>
  );
};

export default PropertyDetailHeader;
