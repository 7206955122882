import FormikInputField from 'components/Fields/FormikInputField';
import { Field, Form } from 'formik';
import React from 'react';
import { Col, InputGroup, Row } from 'reactstrap';

const AuctionDatesForm = ({ resetForm, isValid, dirty }) => {
  return (
    <Form>
      <Row>
        <Col className="px-0 ">
          <Col className="d-flex justify-content-between my-3">
            <h6 className="mb-0 font-size-18">Auction Dates</h6>
            <span className="font-size-12">30 Char limit</span>
          </Col>
          {[1, 2, 3, 4].map((num) => (
            <Col md={12} key={`auction${num}`}>
              <Row className="search-result-header">
                <Col md="12">
                  <InputGroup size="medium">
                    <Field
                      id={`auction.auction_${num}`}
                      name={`auction.auction_${num}`}
                      type="text"
                      component={FormikInputField}
                      placeholder={`Auction ${num}`}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
          ))}
        </Col>
      </Row>
      {/* <Row className="mt-2">
        <Col className="d-flex justify-content-between">
          <Button
            type="button"
            color="muted"
            onClick={() => resetForm()}
            size="sm"
          >
            Cancel
          </Button>
          <div>
            <Button
              type="submit"
              color="primary"
              className="ml-2"
              size="sm"
              outline
              disabled={!isValid || !dirty}
            >
              Save
            </Button>
            <Button
              type="submit"
              color="primary"
              className="ml-2"
              size="sm"
              disabled={!isValid || !dirty}
            >
              Update
            </Button>
          </div>
        </Col>
      </Row> */}
    </Form>
  );
};

export default AuctionDatesForm;
