import React, { useContext, useEffect, useState } from 'react';
import { isEqual, isObject } from 'lodash';
import { Form, Field } from 'formik';
import {
  InputGroup,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

import FormikInputField from 'components/Fields/FormikInputField';
import DetailViewNextButton from 'pages/Search/common/DetailViewNextButton';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import Map from 'common/Map';
import PopupForm from '../ProjectInstructionModal';
import { useQuery } from '@apollo/client';
import { GET_ASSESTS_DETAILS } from 'pages/Search/CrexiSearchGraphQL';
import SingleFileUploader from 'common/SingleFileUploader';
import { toast } from 'react-toastify';

const CallToActionForm = ({
  values,
  refetch,
  fieldKey,
  initialValues,
  getAssetDetails,
}) => {
  const { handleEditAsset } = useContext(assetDetailContext);
  const [modal, setModal] = useState(false);
  const [mapImage, setMapImage] = useState('');
  const [modalStatus, setModalStatus] = useState('image');

  const openMapBox = (isMap) => {
    setModalStatus(isMap);
    setModal(!modal);
  };

  const {
    asset: { id },
  } = getAssetDetails;

  useEffect(() => {
    if (
      isObject(initialValues[fieldKey]) &&
      !isEqual(initialValues[fieldKey], values[fieldKey])
    ) {
      const assetData = { ...getAssetDetails };
      assetData[fieldKey] = values[fieldKey];
      handleEditAsset(true, assetData);
    }
  }, [values]);

  const {
    loading,
    error,
    data,
    refetch: refetchData,
  } = useQuery(GET_ASSESTS_DETAILS, {
    variables: {
      assetId: id ? parseInt(id, 10) : null,
    },
    fetchPolicy: 'network-only',
    skip: !id, // Skip the query if `id` is not provided
  });

  useEffect(() => {
    if (data) {
      const mapScreenshot = data.getAssetDetails?.mapScreenshot;
      if (mapScreenshot) {
        setMapImage(mapScreenshot);
      } else {
        toast.error('No mapScreenshot found');
      }
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Row>
      <Col md="6">
        <Card className="shadow border-radius-8px">
          <CardBody className="px-3">
            <div className="align-items-center d-flex">
              <div className="w-50">
                <label>Upload Property Boundary Image</label>
                <div className="bg-grey align-items-center justify-content-center px-4 py-4 d-flex">
                  <Button
                    color="primary"
                    size="sm"
                    outline
                    onClick={() => openMapBox('image')}
                  >
                    {mapImage
                      ? 'Reupload Boundary Image'
                      : 'Upload Boundary Image'}
                  </Button>
                </div>
              </div>
              <div className="px-2">Or</div>
              <div className="w-50">
                <label>Draw a boundary map</label>
                <div
                  className=" align-items-center d-flex justify-content-center px-5 py-4"
                  style={{ background: '#ddffdd' }}
                >
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    onClick={() => openMapBox('mapbox')}
                  >
                    {mapImage ? 'Redraw VidMap' : 'Open VidMap'}
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="shadow border-radius-8px mt-4">
          <CardBody className="px-3">
            <Form>
              <Row className="search-result-header">
                <Col>
                  <InputGroup size="medium">
                    <Field
                      id="callToActionText"
                      name="callToAction.text"
                      type="textarea"
                      component={FormikInputField}
                      label="Add call to action text"
                      placeholder="Type here"
                      autoFocus
                      className="call-action-field font-size-14"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="search-result-header">
                <Col>
                  <div className="example-div">
                    <span className="mb-1">For Example:</span>
                    <div className="mb-0">
                      “Request your bidding package! Auction June 7 - 9”
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col className="px-2">
                  <DetailViewNextButton
                    values={values}
                    fieldKey={fieldKey}
                    getAssetDetails={getAssetDetails}
                    isValid
                    refetch={refetch}
                  />
                </Col>
              </Row> */}
            </Form>
          </CardBody>
        </Card>
      </Col>

      <Col md="6">
        {/* <Card className="shadow border-radius-8px h-100">
          <CardBody className="px-3">
            <label>Property Address</label>
            <p className="font-size-14 semi-bold">
              {getAssetDetails?.asset?.address}
            </p>
            <div className="mt-2 mb-4">
              <a
                href={`https://www.google.com/maps?q=${getAssetDetails?.asset?.address}`}
                rel="noreferrer"
                className="btn btn-primary btn-sm"
                target="_blank"
              >
                Map Flight Area
              </a>
            </div>
          </CardBody>
        </Card> */}
        {data?.getAssetDetails?.mapScreenshot && (
          <Card className="shadow border-radius-8px h-100">
            <CardBody className="px-3">
              <img
                src={`${data?.getAssetDetails?.mapScreenshot}?t=${Date.now()}`}
                alt="map"
                className="w-100 object-fit-cover"
                height={400}
              />
            </CardBody>
          </Card>
        )}
      </Col>

      <Modal
        isOpen={modal}
        toggle={openMapBox}
        className={`modal-dialog-centered ${
          modalStatus === 'mapbox' ? 'modal-xl' : ''
        }`}
      >
        <ModalHeader toggle={openMapBox}>
          {modalStatus === 'mapbox'
            ? 'Map Flight Area'
            : 'Upload Boundary Image'}
        </ModalHeader>
        <ModalBody style={{ height: '80vh' }}>
          {modalStatus === 'mapbox' ? (
            <Map
              getAssetDetails={getAssetDetails}
              openMapBox={openMapBox}
              refetchData={refetchData}
              mapImage={mapImage}
            />
          ) : (
            <SingleFileUploader
              getAssetDetails={getAssetDetails}
              openMapBox={openMapBox}
              refetchData={refetchData}
            />
          )}

          <PopupForm />
        </ModalBody>
      </Modal>
    </Row>
  );
};

export default CallToActionForm;
