import React, { lazy } from 'react';
import { cloneDeep, isEqual, isObject, pick, values } from 'lodash';

import CitiesWithIn200Miles from 'pages/Search/components/PropertyDetails/components/CitiesWithIn200Miles';
import CitiesIn100Miles from 'pages/Search/components/PropertyDetails/components/CalloutsIn100Miles';
import CitiesIn10Miles from 'pages/Search/components/PropertyDetails/components/CalloutsIn10Miles';
import CallToAction from 'pages/Search/components/PropertyDetails/components/CallToAction';
import InteriorVideoContact from 'pages/Search/components/PropertyDetails/components/InteriorVideoContact';
import Summary from 'pages/Search/components/PropertyDetails/components/Summary';
import AuctionBrokerDetails from './components/PropertyDetails/components/AuctionBrokerDetails';

const routePropertyDetail = import(
  'pages/Search/components/PropertyDetails/components/PropertyDetail'
);
const PropertyDetail = lazy(() => routePropertyDetail);

export const TABS = [
  {
    tabId: 0,
    label: 'Property Details',
    link: '/details',
    type: `details`,
    path: '/crexi-search/property-detail/:assetId/:tab',
    component: PropertyDetail,
  },
  {
    tabId: 1,
    label: 'Cities within a 200 mile radius',
    link: `/cities-in-200-miles`,
    type: `cities-in-200-miles`,
    path: '/crexi-search/property-detail/:assetId/:tab',
    component: CitiesWithIn200Miles,
  },
  {
    tabId: 2,
    label: 'Callouts within 100 Miles',
    link: '/cities-in-100-miles',
    type: `cities-in-100-miles`,
    path: '/crexi-search/property-detail/:assetId/:tab',
    component: CitiesIn100Miles,
  },
  {
    tabId: 3,
    label: 'Callouts within 10 Miles',
    link: '/cities-in-10-miles',
    type: 'cities-in-10-miles',
    path: '/crexi-search/property-detail/:assetId/:tab',
    component: CitiesIn10Miles,
  },
  {
    tabId: 4,
    label: 'Mapping & Call to Action',
    link: '/call-to-action',
    type: 'call-to-action',
    path: '/crexi-search/property-detail/:assetId/:tab',
    component: CallToAction,
  },
  {
    tabId: 5,
    label: 'Auction Dates and Broker',
    link: '/auction-broker-details',
    type: 'auction-broker-details',
    path: '/crexi-search/property-detail/:assetId/:tab',
    component: AuctionBrokerDetails,
  },
  {
    tabId: 6,
    label: 'Interior Video Contact',
    link: '/interior-video-contact',
    type: 'interior-video-contact',
    path: '/crexi-search/property-detail/:assetId/:tab',
    component: InteriorVideoContact,
  },
  {
    tabId: 7,
    label: 'Order Summary',
    link: '/summary',
    type: 'summary',
    path: '/crexi-search/property-detail/:assetId/:tab',
    component: Summary,
  },
];

export const getTab = (pathname) => {
  const params = pathname.split('/');
  return params[params.length - 1];
};

export const getCurrentTab = (pathname) => {
  return TABS.filter((TAB) => TAB.type === getTab(pathname))[0];
};

export const verifyIVCEmptyFields = (interiorVideoContactObj, FIELDS) => {
  const tempIVC = pick(interiorVideoContactObj, FIELDS);
  return values(tempIVC).every((item) => {
    if (!item) return true;
    return false;
  });
};
